<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('language.disabled-languages') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
          <div v-show="disabledLangauges">
            <b-list-group>
              <b-list-group-item v-for="lang in disabledLangauges" :key="lang.id" class="d-flex align-items-center" :to="{ name : 'EditLanguage', params: { languageShortName: lang.shortName }}">
                <b-avatar variant="primary" :text="lang.shortName" class="mr-4"></b-avatar>
                <h2 class="display-5">{{ lang.name }}</h2>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div v-show="!disabledLangauges">
            <b-alert variant="info" show>
              $t('language.no-disabled-languages')
            </b-alert>
          </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import {BAvatar, BAlert} from 'bootstrap-vue'
export default {
  name : "DisabledLanguages",
  components : { NavBar, BAvatar, BAlert },
  data: function() {
    return {
      disabledLangauges: [],
      infoMessages: null,
      showOverlay: true
    }
  },
  created : function() {
    this.$http.get("/cms/api/languages/disabled/", { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.disabledLangauges = r.data;
      }).catch(e => { this.handleError(e) })
      this.showOverlay = false;
  }
}
</script>

<style scoped>
  .b-overlay-wrap {min-height: 200px;}
</style>
